import actions from './actions'

const initialState = {
  role: '',
  authorized: false,
  loading: false,
  setNewPassword: false,
  isLoggedIn: false,
  resetPassword: false,
  otpSent: false,
  error: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_NEW_PASSWORD_REQUEST:
      return { ...state, ...action.payload, setNewPassword: true }
    case actions.SETUP_MFA_SUCCESS:
      return { ...state, ...action.payload }
    case actions.ENTER_MFA_SUCCESS:
      return { ...state, ...action.payload }
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        setNewPassword: false,
        resetPassword: false,
        loading: false,
        isLoggedIn: true,
        authorized: true,
        user: action.payload,
      }
    default:
      return { ...state, ...action.payload }
  }
}
