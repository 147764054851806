import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, logout } from 'services/user'
import {
  signIn,
  completeNewPassword,
  getCurrentUser,
  signOut,
  forgotPassword,
  resetPasswordWithCode,
  getPreferredMFA,
  setupTOTP,
  checkUser,
  verifyTotpToken,
  setPreferredMFA,
  confirmSignIn,
} from '../../services/awsAmplify'
import actions from './actions'
import localstorage from '../../services/localStorage'

let g_user
export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  try {
    const user = yield call(signIn, { email, password })
    // Check if MFA is not set up
    // const preferredMFA = yield call(getPreferredMFA, user)
    // console.log({ preferredMFA })

    if (user.challengeName === 'MFA_SETUP') {
      // Trigger MFA setup
      const mfaSetupDetails = yield call(setupTOTP, user)
      console.log({ user })
      yield put({
        type: 'user/SETUP_MFA_SUCCESS',
        payload: {
          secretCode: mfaSetupDetails,
          qrCodeURL: `otpauth://totp/AWSCognito:${user.username}?secret=${mfaSetupDetails}&issuer=NRSAdminSite`,
          cognitoUser: user,
          showMFASetup: true,
          loading: false,
        },
      })
      return
    }

    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      yield put({
        type: 'user/ENTER_MFA',
        payload: {
          showMFAInput: true,
          cognitoUser: user,
          loading: false,
        },
      })
      return
    }

    // Continue with normal login flow
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      g_user = user
      yield put({
        type: 'user/SET_NEW_PASSWORD_REQUEST',
        payload: {
          loading: false,
        },
      })
    } else {
      notification.success({
        message: 'Logged In',
        description: `You have successfully logged in to Admin Site of Niranjan Swami's website!`,
      })
      localstorage.setItem('cognitoUser', user)
      yield put({
        type: 'user/LOGIN_SUCCESS',
        payload: { ...user, loading: true },
      })
      // window.location.reload(false)
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: err.message ? err.message : 'Some error occurred',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        error: err.message ? err.message : err,
      },
    })
  }
}

export function* setNewPassword({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  const user = yield call(completeNewPassword, { user: g_user, newPassword: payload })
  console.log({ g_user, user })
  if (user) {
    if (user.challengeName === 'MFA_SETUP') {
      // Trigger MFA setup
      const mfaSetupDetails = yield call(setupTOTP, user)

      yield put({
        type: 'user/SETUP_MFA_SUCCESS',
        payload: {
          secretCode: mfaSetupDetails,
          qrCodeURL: `otpauth://totp/AWSCognito:${user.username}?secret=${mfaSetupDetails}&issuer=NRSAdminSite`,
          cognitoUser: user,
          showMFASetup: true,
          loading: false,
          setNewPassword: false,
        },
      })
      return
    }

    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      yield put({
        type: 'user/ENTER_MFA',
        payload: {
          showMFAInput: true,
          cognitoUser: user,
          loading: false,
          setNewPassword: false,
        },
      })
      return
    }
    notification.success({
      message: 'Successfully Changed Password',
      description: 'You have successfully set your password.',
    })
    localstorage.setItem('cognitoUser', user)
    yield put({
      type: 'user/LOGIN_SUCCESS',
      payload: user,
    })
    window.location.reload(false)
  }
}

export function* requestOtp({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  const email = payload.email
  console.log('EMAIL>>>>>>>>', email)
  const success = yield call(forgotPassword, email)
  console.log(success)
  if (success) {
    console.log('aya yaha')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        resetPassword: true,
      },
    })
  }
}

export function* resetPassword({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  try {
    const { username, code, new_password } = payload
    console.log(payload)
    const success = yield call(resetPasswordWithCode, username, code, new_password)
    console.log(success)

    notification.success({
      message: 'Successfully Changed Password',
      description:
        'You have successfully reset your password. Please login with your new password!',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        resetPassword: false,
        resetPasswordSuccess: true,
      },
    })
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: err.message ? err.message : 'Some error occurred',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        error: err.message ? err.message : err,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  const response = yield call(currentAccount)
  console.log('RESPONSE ======>', response)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        role: 'admin',
        authorized: true,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(signOut)
  localstorage.setItem('cognitoUser', null)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
    },
  })
}

export function* verifyMFASetupSaga({ payload }) {
  try {
    console.log('verifymfa', { payload })
    yield call(verifyTotpToken, payload.cognitoUser, payload.code)
    yield call(setPreferredMFA, payload.cognitoUser, 'TOTP')

    const user = yield call(checkUser)

    yield put({
      type: 'user/VERIFY_MFA_SETUP_SUCCESS',
      payload: user,
    })

    notification.success({
      message: 'Logged In',
      description: `You have successfully logged in to Admin Site of Niranjan Swami's website!`,
    })
    localstorage.setItem('cognitoUser', user)
    yield put({
      type: 'user/LOGIN_SUCCESS',
      payload: { ...user, loading: true },
    })
    window.location.reload(false)
  } catch (error) {
    yield put({
      type: 'user/VERIFY_MFA_SETUP_FAILURE',
      payload: error,
    })
  }
}

export function* confirmUserSignIn({ payload }) {
  try {
    yield call(confirmSignIn, payload.cognitoUser, payload.code)

    const user = yield call(checkUser)

    yield put({
      type: 'user/ENTER_MFA_SUCCESS',
      payload: user,
    })

    notification.success({
      message: 'Logged In',
      description: `You have successfully logged in to Admin Site of Niranjan Swami's website!`,
    })
    localstorage.setItem('cognitoUser', user)
    yield put({
      type: 'user/LOGIN_SUCCESS',
      payload: { ...user, loading: true },
    })
    window.location.reload(false)
  } catch (error) {
    yield put({
      type: 'user/ENTER_MFA_FAILURE',
      payload: error,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.SET_NEW_PASSWORD, setNewPassword),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.REQUEST_OTP, requestOtp),
    takeLatest(actions.RESET_PASSWORD, resetPassword),
    takeLatest(actions.VERIFY_MFA_SETUP, verifyMFASetupSaga),
    takeLatest(actions.ENTER_MFA, confirmUserSignIn),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
