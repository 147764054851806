const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_NEW_PASSWORD_REQUEST: 'user/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD: 'user/SET_NEW_PASSWORD',
  LOGIN: 'user/LOGIN',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  REQUEST_OTP: 'user/REQUEST_OTP',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  SETUP_MFA: 'user/SETUP_MFA',
  SETUP_MFA_SUCCESS: 'user/SETUP_MFA_SUCCESS',
  SETUP_MFA_FAILURE: 'user/SETUP_MFA_FAILURE',
  VERIFY_MFA_SETUP: 'user/VERIFY_MFA_SETUP',
  VERIFY_MFA_SETUP_SUCCESS: 'user/VERIFY_MFA_SETUP_SUCCESS',
  VERIFY_MFA_SETUP_FAILURE: 'user/VERIFY_MFA_SETUP_FAILURE',
  ENTER_MFA: 'user/ENTER_MFA',
  ENTER_MFA_SUCCESS: 'user/ENTER_MFA_SUCCESS',
  ENTER_MFA_FAILURE: 'user/ENTER_MFA_FAILURE',
  GET_USER: 'user/GET_USER',
}

export default actions
